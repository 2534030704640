
import { defineComponent, } from 'vue';
import { IonPage, IonContent, } from '@ionic/vue';

export default defineComponent({
    name: '404',
    components: {
        IonContent,
        IonPage,
    },
    setup() {
        return {
        }
    },
})
